import React, {
  createContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';
import { useTenants } from 'src/utils/tenants';
 
const SettingsContext = createContext();

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.ONE_DARK,
  activeTenantId: null,
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);
  const availableTenants = useTenants();
  const availableTenantIds = availableTenants.map(tenant => tenant.key);

  useEffect(() => {
    /**
     * Check and set tenantId if:
     * - currentSettings has no activeTenantId
     * - currentSettings has activeTenantId but it's null
     * - currentSettings has an activeTenantId that is NOT in the list of available tenants (user logged in with another userId but tenant was saved) 
     */

    if(
        availableTenants.length > 0 && 
        (!('activeTenantId' in currentSettings) || currentSettings.activeTenantId === null || !(currentSettings.activeTenantId in availableTenantIds))
      ) {
      if(availableTenants.length > 0) {
        setCurrentSettings(_.merge({}, currentSettings, {
          activeTenantId: availableTenants[0].key 
        }));
        }
    }
  }, [availableTenants]);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
