import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useTenants } from "src/utils/tenants";

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));


// function parseAvailableTenants(domainRoles) {
//   return Object.keys(domainRoles).map((key) => {
//     return {key, name: `BM-${key.slice(0,4)}-${key.slice(4, key.length)}`};
//   });
// }

function Settings() {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    activeTenantId: settings.tenantId
  });

  // const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const { user } = useAuth0();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  const availableTenants = useTenants();

  // const getTenants = useCallback(async () => {
  //   console.log(user);
  // });

  // useEffect(() => {
  //   const namespacedRolesKey = `https://${auth0Audience}/roles`;
    
  //   if(!(namespacedRolesKey in user) || !("domainRoles" in user[namespacedRolesKey])) {
  //     throw new Error("doaminRoles is not available in user token.");
  //   }

  //   const tenants = parseAvailableTenants(user[namespacedRolesKey].domainRoles);

  //   setAvailableTenants(tenants);
  // }, []);


  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Settings
        </Typography>
        <Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
              />
            )}
            label="RTL"
          />
        </Box>
        <Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label="Responsive font sizes"
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>

        <Box mt={2}>
        <TextField
            fullWidth
            label="Tenant"
            name="tenant"
            onChange={(event) => handleChange('activeTenantId', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.activeTenantId}
            variant="outlined"
          >
            {availableTenants.map((tenant) => (
              <option
                key={tenant.key}
                value={tenant.key}
              >
                {tenant.name}
              </option>
            ))}
          </TextField>
          
        </Box>


        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
