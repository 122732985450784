import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

function parseAvailableTenants(domainRoles) {
  return Object.keys(domainRoles).map((key) => {
    return {key, name: `BM-${key.slice(0,4)}-${key.slice(4, key.length)}`};
  });
}

function useTenants() {
  const [availableTenants, setAvailableTenants] = useState([]);
  const { user } = useAuth0();

  useEffect(() => {
    const namespacedRolesKey = `https://${auth0Audience}/roles`;
    let tenants = [];

    if(user) {
      if(!(namespacedRolesKey in user) || !("domainRoles" in user[namespacedRolesKey])) {
        throw new Error("doaminRoles is not available in user token.");
      }
  
      tenants = parseAvailableTenants(user[namespacedRolesKey].domainRoles);  
    }

    setAvailableTenants(tenants);
  }, [user]);

  return availableTenants;
}

export { useTenants };