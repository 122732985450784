import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const Auth0ProtectedRoute = ({ component, ...args }) => (
  <Route
        // eslint-disable-next-line no-undef
    component={withAuthenticationRequired(component, {
      //       // If using a Hash Router, you need to pass the hash fragment as `returnTo`
      //       // returnTo: () => window.location.hash.substr(1),
    })}
    {...args}
  />
);
Auth0ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  render: PropTypes.func
};

export default Auth0ProtectedRoute;
